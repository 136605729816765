<template>
    <SelectInput
        class="period-filter"
        :options="formattedPeriodsFilterOptions"
        :modelValue="periodsFilterValue"
        :clearable="false"
        :adaptive="false"
        customSelectedOption
        customOptions
        object-mode
        @update:modelValue="handlePeriodSelection"
    >
        <template #selected-option="{ selectedOption }">
            <Icon
                name="filter"
                class="filter-icon h-5 w-5"
            />
            <span class="ml-2">
                {{ selectedOption?.value }}
            </span>
        </template>

        <template #option="{ option }">
            <div class="flex">
                {{ option?.value }}
                <Icon
                    v-if="isOptionSelected(option)"
                    name="checkmark"
                    class="check-icon"
                />
            </div>
        </template>
    </SelectInput>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watch } from 'vue';
import { PERIODS_FILTER_OPTIONS } from '@/utils/constants/accounting';
import SelectInput from '@/components/ui/SelectInput';
import Icon from '@/components/ui/Icon';
import { periodsMap } from '@/utils/accounting';

const props = defineProps({
    phaseStartDate: {
        type: [String, Date],
        default: null,
    },
    resetValueToDefault: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['periodRange']);

const formattedPeriodsFilterOptions = computed(() => {
    return PERIODS_FILTER_OPTIONS.map(period => {
        return {
            ...period,
            startDate: periodsMap(period.key, props.phaseStartDate)?.startDate,
            endDate: periodsMap(period.key, props.phaseStartDate)?.endDate,
        };
    }).filter(period => {
        if (period.endDate && props.phaseStartDate && period.key !== 'all') {
            return new Date(period.startDate) > new Date(props.phaseStartDate);
        }
        return period;
    });
});

const periodsFilterValue = ref(formattedPeriodsFilterOptions.value[1]); // current month is the default option

watch(
    periodsFilterValue,
    (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
            emit('periodRange', newVal);
        }
    },
    { immediate: true }
);

watch(
    () => props.resetValueToDefault,
    value => {
        if (value) {
            periodsFilterValue.value = formattedPeriodsFilterOptions.value[1];
        }
    },
    { immediate: true }
);

const handlePeriodSelection = period => {
    periodsFilterValue.value = period;
};

const isOptionSelected = option => option?.key === periodsFilterValue?.value?.key;
</script>

<style scoped>
.period-filter {
    margin-top: -0.938rem;
    width: 11.688rem;
    margin-left: auto;
}

.period-filter :deep(.vs__dropdown-toggle) {
    max-height: 3rem;
    font-size: 0.875rem;
}

.period-filter :deep(.vs__dropdown-menu) {
    max-height: 14.375rem;
}

.period-filter :deep(.vs__dropdown-option) {
    padding: 0.625rem;
}

.check-icon {
    @apply ml-2;
    min-width: 0.8125rem;
    width: 0.8125rem;
    height: 0.8125rem;
    margin-left: auto;
}

.check-icon,
.check-icon.w-5,
.check-icon.h-5 {
    width: 1.25rem;
    height: 1.25rem;
}

.check-icon--selected {
    @apply bg-purple-500 border-purple-500;
}
</style>
